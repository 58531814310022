import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { rhythm } from "../../utils/typography"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Services = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Video Production Services - Services" />
      <article style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <header>
          <h1>Video Production Services</h1>
        </header>

        <Image
          fluid={data.icons.childImageSharp.fluid}
          alt="Do something great"
          style={{
            maxWidth: `1000px`,
            marginTop: rhythm(2),
            marginBottom: rhythm(2),
            marginLeft: `auto`,
            marginRight: `auto`,
          }}
        />

        <p style={{ textAlign: `center` }}>
          I shoot, edit, direct and produce and you can hire me for individual
          services or for all the above <br />I love tailoring bespoke video
          solutions and will work with you to create the most effective video
          for your business on message and on budget!
        </p>

        <div className="tight">
          <h2>The production process</h2>
          <h3>From initial enquiry to your final film</h3>
        </div>

        <p>
          With any enquiries I always try to find out as much as I can about
          your project to fully understand your objectives. I will of course
          discuss this in detail with you either in person or on the phone but
          if you’re not sure where to start, have a think about these seven
          questions, as they will determine the type of video and how it should
          be shot/produce best:
        </p>

        <div className="feature-table">
          <div>
            <span>Who is the video for?</span>
            <h3>AUDIENCE</h3>
          </div>
          <div>
            <span>Where will it be shown?</span>
            <h3>PLATFORM</h3>
          </div>
          <div>
            <span>What is the purpose of the video?</span>
            <h3>YOUR GOAL</h3>
          </div>
          <div>
            <span>What would you/the client like to communicate?</span>
            <h3>OBJECTIVE</h3>
          </div>
          <div>
            <span>
              Is it part of a bigger offering or a stand-alone product?
            </span>
            <h3>TYPE</h3>
          </div>
          <div>
            <span>How much is the budget for the project?</span>
            <h3>BUDGET</h3>
          </div>
          <div>
            <span>What tone/mood do you envision for the video?</span>
            <h3>STYLE</h3>
          </div>
        </div>

        <p>
          This initial thought process is really important to make sure that all
          objectives are met and your film is aligned with your brand’s values.
          In other words, a certain type of video might work better to achieve
          your goal than another and together we find the best solution for your
          project. Budget will also be a consideration when looking at different
          options - saying that I will always provide you with the best possible
          production value for your budget.
        </p>

        <h2>The brief</h2>
        <p>
          All this information is then worked into a creative brief, which can
          contain a treatment, shot list, if needed a script, branding
          guidelines and any other assets required for the project. We will also
          discuss the format ie. is the video for your website or YouTube and
          will it need to be in 16:9 or will it be content for your Instagram
          feed in which case you will need 1:1 videos or Instagram stories which
          are usually 9:16. Don’t worry if this is sounding too technical
          already, I will go through the different formats with you and explain
          why determining this at the beginning is important for the actual
          filming and editing.
        </p>

        <h2>Creative considerations</h2>
        <p>
          In video production we often speak of ‘a piece coming together’. What
          do I mean by that? It’s when a story flows, the visuals help tell your
          narrative, the music enhances emotions and all of those elements
          working together creating a well rounded piece that just feels right
          when you watch it. Yes getting all of this right is partly a creative
          process but more importantly it’s defining elements like the look and
          feel of your film, the style, music, tone of voice and grade early on.
          Examples of what you like and equally what you don’t like can be very
          useful at this point. Does your video require a voice over or any
          on-screen text/ graphics? These are gain stylistic choices which are
          obviously very subjective but can also vary depending on the type of
          video you are after. This is something we can discuss in more detail
          in a meeting or on the phone in the briefing stage of the project.
        </p>

        <h2>Time frames</h2>
        <p>
          I found that the best approach is to work backwards. So unless there
          is a specific date that the filming needs to take place on (ie. an
          event), it makes sense to look at the release or publishing date of
          your video. So for example are you aunching a new service in three
          months time and would like the film to be ready for then? Do you maybe
          also need a few short edits to start promoting this offering on social
          media a month prior to the launch? Or have you just done a rebrand and
          need an updated brand film for your website? Is your aim to release
          regular video content ie. an interview or advice series
        </p>
        <p>
          As you can see there are so many different scenarios and a breadth of
          video options for different objectives, that it is crucial to define
          the aim of your project very early on and depending on the complexity
          of the project I would allow for a turnaround time of at least two
          weeks but ideally a month. Smaller or time sensitive projects can of
          course be turned around faster (depending on availability).
        </p>
        <p>
          The next step will be putting together a production schedule, booking
          in shoot dates and edit days. It’s advisable to work out a production
          timeline so that everyone is on the same page in regards to review and
          edit changes, revised versions and meeting the final deadline. Your
          final exports are usually being delivered digitally and making sure
          they are in the right format agreed previously.
        </p>

        <div className="tight">
          <h2>WANT TO HEAR MORE ABOUT HOW IT WILL WORK?</h2>
          <h3>SEND ME AN EMAIL AND WE’LL ARRANGE A CHAT</h3>
        </div>
      </article>
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    icons: file(absolutePath: { regex: "/production-services.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
